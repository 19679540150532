import React from "react"
import styled from "styled-components"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Frame from "../components/frame"

import Section from '../components/section';
import DotBackground from '../components/dotbackground';
import AnalyticsLink from '../components/analytics/analyticslink';

import { colors, dimensions } from '../shared/styles';
import content from '../data/static-content.js';
import makeBlogArticlePath from "../shared/makeBlogArticlePath"

/* ---------------------------------------------- */
/* ---------------------------------------------- */

const MainSection = styled(Section)`
  height : 100%;
  width : 100%;
  padding-top : ${dimensions.headerHeight};
  padding-bottom : 15rem;
  position : relative;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content : flex-start;
  overflow : hidden;
`;

const Background = styled(DotBackground)`
  position : absolute;
  top      : 30rem;
  left     : 6%;
  width    : 88%;
  height   : 85%;
  z-index : -1;
`;

const TitleFrameContainer = styled.div`
  display : flex;
  margin : 3rem auto 7rem auto;

  @media screen and (max-width: 425px) {
    margin : 3rem 5rem 7rem 5rem;
    flex-align : flex-end;
  }
`;

const ArticleList = styled.div`
  padding-top : 5rem;
  display : flex;
  flex-direction : row;
  justify-content : center;
  flex-wrap : wrap;
  max-width : 1440px;
`;

const ArticleCard = styled.div`
  width : 30rem;
  height : 45rem;
  border : 1px solid black;
  background-color : white;
  margin : 3rem;

  & h3 {
    font-size : 2rem;
    line-height : 3rem;
    margin : 2rem;
  }
`;

const ArticleImage = styled.img`
  width : 90%;
  margin : 5%;
`;

const ArticleDate = styled.div`
  color : ${colors.blue};
  margin : 2rem;
  font-size : 1.6rem;
  font-weight : bold;
`;

const CardLink = styled(AnalyticsLink)`
  text-decoration : none;
  color : black;

  &:active, &:visited {
    color : inherit;
  }
`

/*const ShadedArticleCardOne = styled(ArticleCard)`
  border-color : #AAAAAA;
  borderWidth  : 2px;
`
const ShadedArticleCardTwo = styled(ArticleCard)`
  border-color : #DDDDDD;
  borderWidth  : 2px;
`*/


const BlogPage = ({data}) => {

  console.log(data);
  return (
    <Layout>
      <SEO title="Blog" />
      <MainSection>
        <Background />
        <TitleFrameContainer>
          <Frame>
            <h1>{content.blog.title}</h1>
          </Frame>
        </TitleFrameContainer>
        {/*<h2>{content.blog.subtitle}</h2>*/}
        <ArticleList>
          {
            data.allMarkdownRemark.edges.map(
              ({ node }) => {
                const formattedDate = new Intl.DateTimeFormat(undefined, {month : 'long', day:'numeric', year : 'numeric'}).format(new Date(node.frontmatter.date));
                const path = makeBlogArticlePath(node.frontmatter.title);
                return (
                  <CardLink 
                    to={path}
                    action={`blog->${path}`}
                  >
                    <ArticleCard key={path}>
                      <ArticleDate>{formattedDate}</ArticleDate>
                      <ArticleImage src={node.frontmatter.thumbnail} />
                      <h3>{node.frontmatter.title}</h3>
                    </ArticleCard>
                  </CardLink>
                );
              }
            )
          }
        </ArticleList>
      </MainSection>
    </Layout>
  )
   
};

export const query = graphql`
{
  allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    limit: 1000
  ) {
    edges {
      node {
        frontmatter {
          date
          title
          thumbnail
          author
          authorAvatar
        }
      }
    }
  }
}
`;

export default BlogPage
